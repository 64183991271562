import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { resizeNavBar } from '../functions'
import MiContexto from '../MiContexto'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';





export default function Resources(props) {

    const reseller = useContext(MiContexto).reseller

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        resizeNavBar()

    }, [reseller])


    let webSite = ["https://catwatchful.es/", "https://catwatchful.com/"]

    const handleOnClick = () => {
        window.open(webSite[0], "_blank");
    }




    return (
        <div className="container small col-12 col-md-6" style={{ paddingTop: "30px", marginBottom: "60px" }} onClick={() => resizeNavBar()}>

            <Zoom><img src="/images/logo-solo.png" alt="" width={"125px"} /></Zoom>
           
            
            <div className="text-left p-2 mt-2">
                {t('About.subtitle1')}
               <div className="text-center mt-2"> <Fade><img onClick={handleOnClick} src="/images/googleplay.svg" alt="" width={"160px"} /></Fade></div>
            </div>

            <div className="text-left p-2">{t('About.tag1')}</div>
            <div className="text-left p-2">{t('About.tag2')}</div>

            <Fade>
                <div className="mt-3 text-left p-2">
                    <h6 className="text-uppercase">{t('About.features')}</h6>
                    <ul>
                        <li className="my-2"><span className="text-warning">{t('About.callRecTitle')}</span> {t('About.callRecDesc')}</li>
                        <li className="my-2"><span className="text-warning">{t('About.micRecTitle')}</span> {t('About.micRecDesc')}</li>
                        <li className="my-2"><span className="text-warning">{t('About.screenShotsTitle')}</span> {t('About.screenShotsDesc')}</li>
                        <li className="my-2"><span className="text-warning">{t('About.keyloggerTitle')}</span> {t('About.keyloggerDesc')}</li>
                        <li className="my-2"><span className="text-warning">{t('About.socialTitle')}</span> {t('About.socialDesc')}</li>
                        <li className="my-2"><span className="text-warning">{t('About.controlTitle')}</span> {t('About.controlDesc')}</li>
                    </ul>
                </div>
            </Fade>

            <div className="mt-2 p-2 text-left">
                <h6 className="text-uppercase">{t('About.title2')}</h6>
                <div className="my-2">{t('About.subTitle2')}</div>
                <div>{t('About.subTitle3')}</div>
            </div>


            <button className="btn btn-outline-light my-2" onClick={handleOnClick}>{t('About.btnWeb')}</button>

            <div>

            </div>

        </div>
    )
}
