import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate, useLocation, Link } from 'react-router-dom';

import Cookies from 'js-cookie';
import axios from "axios";

import MiContexto from './MiContexto'

import './App.css';
import Dashboard from "./components/Dashboard";
import Login from "./components/Login/Login";
import Signup from "./components/Login/Signup";
import ForgotPass from "./components/Login/ForgotPass";
import Account from "./components/Account";
import WithDraw from "./components/WithDraw";
import Sales from "./components/Sales";
import HowToUse from "./components/HowToUse";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Support from "./components/Support";
import Resources from "./components/Resources";
import About from "./components/About";
import StartSelling from "./components/StartSelling/StartSelling";

import { PendingAprovalPromt, AprovalSuccessfullyPromt } from "./components/PromptsStatus";


import { URL_BACKEND, COOKIE_NAME } from "./config";


function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState(null)
  const [reseller, setReseller] = useState(null)



  useEffect(() => {

    //Verifico si hay usuario en la URL o en La COOKIE
    const queryParams = new URLSearchParams(window.location.search);
    let usrFromParams = queryParams.get('usr');
    const arrayEmailPassword = atob(usrFromParams).split("|")

    if (arrayEmailPassword.length == 2) {

      //Usuario por parametros B64 (usr="email|pass")
      let email = arrayEmailPassword[0]
      let password = arrayEmailPassword[1]
      handleLogin(email, password)

    } else {

      const cookie_usuario = Cookies.get(COOKIE_NAME);
      if (cookie_usuario) {
        //Usuario en la Cookie
        const currentUser = JSON.parse(cookie_usuario);
        setUser(currentUser)
        loadResellerData(currentUser)
      }
    }

  }, [])


  useEffect(() => {
    //Verifico si el usuario esta pendiente de aprobacion o si ya fue aprobado
    if (user && reseller) {
      if (reseller.info.estado === 0) {
        window.$('#pendingAprovalModal').modal();
      } else {
        if (reseller.info.estado === 1) {
          if (reseller.info.msgAprovalShow === 0) {
            //Muestro mensaje de aprobacion
            window.$('#aprovalSuccessfullyModal').modal()
            axios.put(`${URL_BACKEND}/reseller/msgAprovalShow/${user.authParams}`).then(response => { }).catch(e => { console.log("Error", e) });
          }
        }
      }
    }
  }, [user, reseller])




  useEffect(() => {
    if (location.pathname === "/logout") handleLogout()
  }, [location])



  const handleSignUp = async (newReseller) => {
    return axios.post(`${URL_BACKEND}/newReseller`, newReseller)
      .then(resp => {
        return resp.data
      }).catch(error => {
        console.error(error);
        return 'ERR'
      });

  }


  const handleLogin = async (email, pass) => {

    return axios.get(`${URL_BACKEND}/login/?email=${email}&pass=${pass}`)
      .then(resp => {
        const { logcode, usuario, authToken } = resp.data

        if (logcode === 1 && usuario && authToken) {

          const currentUser = {
            id: usuario.resellerId,
            authToken: authToken,
            authParams: `?userid=${usuario.resellerId}&authToken=${authToken}`,
            email: usuario.email,
            pass: usuario.pass,
          }

          Cookies.set(COOKIE_NAME, JSON.stringify(currentUser), { expires: 1 });

          setUser(currentUser)
          loadResellerData(currentUser)

          return true
        } else {
          return false
        }


      }).catch(error => {
        console.error(error);
        return 'ERR'
      });
  }


  const handleResellerEdit = async (resellerEdited) => {

    if (resellerEdited && user) {
      console.log("resellerEdited: ", resellerEdited)
      await axios.put(`${URL_BACKEND}/reseller/${user.authParams}`, resellerEdited)
        .then(response => {
          console.log("resellerEdited: ", response)
        })
        .catch(e => {
          console.log("Error", e)
        });
    }

  }

  const handleSalesNotifyViewed = async () => {


    console.log("handleSalesNotifyViewed: ", user)

    await axios.put(`${URL_BACKEND}/reseller/salesNotifyViewed/${user.authParams}`)
      .then(response => {
        console.log("setSalesNotifyViewed: ", response)
      })
      .catch(e => {
        console.log("Error", e)
      });

  }


  const handleResellerQuery = async (query) => {
    console.log("ResellerQuery: ", query)
    return axios.put(`${URL_BACKEND}/reseller/newQuery/${user.authParams}`, query)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log("Error", e)
        return e.message
      });
  }

  const handleChangePassword = async (newPassword) => {
    return axios.put(`${URL_BACKEND}/reseller/changePassword/${user.authParams}`, { pass: newPassword })
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log("Error", e)
        return e.message
      });
  }

  const handleForgotPass = async (email) => {
    return axios.post(`${URL_BACKEND}/forgotPass`, { email: email })
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log("Error", e)
        return e.message
      });
  }




  const handleLogout = () => {
    console.log("SINGED OUT OK")
    setUser(null)
    setReseller(null)
    Cookies.remove(COOKIE_NAME);
    navigate("/")
  }


  const loadResellerData = async (user) => {

    if (user) {
      await axios.get(`${URL_BACKEND}/reseller/info/${user.authParams}`)
        .then(response => {

          if (response.data.code != 'ERR') {

            console.log("ressellerrr OK!: ", response.data)
            setReseller(response.data)
            navigate('/dashboard');


          } else {
            console.log("Error", response.data.message)
            handleLogout()
          }
        })
        .catch(e => {
          console.log("Error", e)
          handleLogout()
        });

    }

  }



  return (

    <div className="App">


      <PendingAprovalPromt />
      <AprovalSuccessfullyPromt />


      <MiContexto.Provider value={{
        user: user,
        reseller: reseller,
      }}>



        {/* {!reseller && <span className="p-2 text-uppercase  fondoTransOscuro fixed-top" style={{ color: "#8EDC80" }}><i className="fa fa-dollar mr-2 pt-1" /> Catwatchful Reseller Program</span>} */}


        {reseller && <Header reseller={reseller} handleLogout={handleLogout} />}


        <div style={{ marginTop: "50px" }}>
          <Routes>

            <Route path="/" element={<Login handleLogin={handleLogin} />} />
            <Route path="login" element={<Login handleLogin={handleLogin} />} />
            <Route path="logout" element={<Login handleLogin={handleLogin} />} />
            <Route path="signup" element={<Signup handleSignUp={handleSignUp} handleLogin={handleLogin} />} />
            <Route path="forgotPass" element={<ForgotPass handleForgotPass={handleForgotPass} />} />

            {/* {reseller && reseller.info.estado === 1 && */}
            {reseller &&
              <React.Fragment>

                {<Route path="account" element={<Account handleResellerEdit={handleResellerEdit} handleChangePassword={handleChangePassword} />} />}
                {<Route path="dashboard" element={<Dashboard />} />}
                {<Route path="withdraw" element={<WithDraw />} />}
                {<Route path="sales" element={<Sales handleSalesNotifyViewed={handleSalesNotifyViewed} />} />}
                {<Route path="howtouse" element={<HowToUse />} />}
                {<Route path="resources" element={<Resources />} />}
                {<Route path="startSelling" element={<StartSelling/>} />}
                {<Route path="about" element={<About />} />}
                {<Route path="support" element={<Support handleResellerQuery={handleResellerQuery} />} />}


              </React.Fragment>
            }




          </Routes>
        </div>

      </MiContexto.Provider>


      <Footer />

    </div>




  );



}

export default App;
